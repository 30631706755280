/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PagableSincerityResults,
} from '../models/index';
import {
    PagableSincerityResultsFromJSON,
    PagableSincerityResultsToJSON,
} from '../models/index';

export interface GetResultsRequest {
    size?: number;
    includeDemoVideos?: boolean;
    ids?: string;
    prevPageLastCreatedAt?: string;
    filter?: string;
    startDate?: string;
    endDate?: string;
}

/**
 * 
 */
export class SincerityApi extends runtime.BaseAPI {

    /**
     * Get Categories
     */
    async getCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("cognito", []);
        }

        const response = await this.request({
            path: `/sincerity/get_categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Categories
     */
    async getCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Results
     */
    async getResultsRaw(requestParameters: GetResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagableSincerityResults>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.includeDemoVideos !== undefined) {
            queryParameters['includeDemoVideos'] = requestParameters.includeDemoVideos;
        }

        if (requestParameters.ids !== undefined) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.prevPageLastCreatedAt !== undefined) {
            queryParameters['prevPageLastCreatedAt'] = requestParameters.prevPageLastCreatedAt;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("cognito", []);
        }

        const response = await this.request({
            path: `/sincerity/results`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagableSincerityResultsFromJSON(jsonValue));
    }

    /**
     * Get Results
     */
    async getResults(requestParameters: GetResultsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagableSincerityResults> {
        const response = await this.getResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async sincerityGetCategoriesOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sincerity/get_categories`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async sincerityGetCategoriesOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sincerityGetCategoriesOptionsRaw(initOverrides);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async sincerityResultsOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sincerity/results`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async sincerityResultsOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sincerityResultsOptionsRaw(initOverrides);
    }

}
