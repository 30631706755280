/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditUpload
 */
export interface EditUpload {
    /**
     * 
     * @type {string}
     * @memberof EditUpload
     */
    fileUUID: string;
    /**
     * 
     * @type {string}
     * @memberof EditUpload
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUpload
     */
    fileMetadata?: string;
}

/**
 * Check if a given object implements the EditUpload interface.
 */
export function instanceOfEditUpload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fileUUID" in value;

    return isInstance;
}

export function EditUploadFromJSON(json: any): EditUpload {
    return EditUploadFromJSONTyped(json, false);
}

export function EditUploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditUpload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileUUID': json['fileUUID'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'fileMetadata': !exists(json, 'fileMetadata') ? undefined : json['fileMetadata'],
    };
}

export function EditUploadToJSON(value?: EditUpload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileUUID': value.fileUUID,
        'fileName': value.fileName,
        'fileMetadata': value.fileMetadata,
    };
}

