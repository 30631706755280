/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SincerityStatus } from './SincerityStatus';
import {
    SincerityStatusFromJSON,
    SincerityStatusFromJSONTyped,
    SincerityStatusToJSON,
} from './SincerityStatus';

/**
 * 
 * @export
 * @interface SincerityResult
 */
export interface SincerityResult {
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    file: string;
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    srcID: string;
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    subtitlesFile: string;
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    downloadFile?: string;
    /**
     * 
     * @type {SincerityStatus}
     * @memberof SincerityResult
     */
    status: SincerityStatus;
    /**
     * 
     * @type {Date}
     * @memberof SincerityResult
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SincerityResult
     */
    completedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    fileExtension: string;
    /**
     * 
     * @type {Date}
     * @memberof SincerityResult
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof SincerityResult
     */
    totalAssessments?: number;
    /**
     * 
     * @type {number}
     * @memberof SincerityResult
     */
    numLikelyTrue?: number;
    /**
     * 
     * @type {number}
     * @memberof SincerityResult
     */
    numLikelyUntrue?: number;
    /**
     * 
     * @type {number}
     * @memberof SincerityResult
     */
    numNeutral?: number;
    /**
     * 
     * @type {number}
     * @memberof SincerityResult
     */
    feedbackRating?: number;
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    feedbackComment?: string;
    /**
     * 
     * @type {number}
     * @memberof SincerityResult
     */
    feedbackTimeStamp?: number;
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    fileMetadata?: string;
    /**
     * 
     * @type {string}
     * @memberof SincerityResult
     */
    predictionsFile?: string;
}

/**
 * Check if a given object implements the SincerityResult interface.
 */
export function instanceOfSincerityResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "srcID" in value;
    isInstance = isInstance && "subtitlesFile" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "fileExtension" in value;

    return isInstance;
}

export function SincerityResultFromJSON(json: any): SincerityResult {
    return SincerityResultFromJSONTyped(json, false);
}

export function SincerityResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SincerityResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file': json['file'],
        'srcID': json['srcID'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'subtitlesFile': json['subtitlesFile'],
        'downloadFile': !exists(json, 'downloadFile') ? undefined : json['downloadFile'],
        'status': SincerityStatusFromJSON(json['status']),
        'createdAt': (new Date(json['createdAt'])),
        'completedAt': !exists(json, 'completedAt') ? undefined : (new Date(json['completedAt'])),
        'fileExtension': json['fileExtension'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'totalAssessments': !exists(json, 'totalAssessments') ? undefined : json['totalAssessments'],
        'numLikelyTrue': !exists(json, 'numLikelyTrue') ? undefined : json['numLikelyTrue'],
        'numLikelyUntrue': !exists(json, 'numLikelyUntrue') ? undefined : json['numLikelyUntrue'],
        'numNeutral': !exists(json, 'numNeutral') ? undefined : json['numNeutral'],
        'feedbackRating': !exists(json, 'feedbackRating') ? undefined : json['feedbackRating'],
        'feedbackComment': !exists(json, 'feedbackComment') ? undefined : json['feedbackComment'],
        'feedbackTimeStamp': !exists(json, 'feedbackTimeStamp') ? undefined : json['feedbackTimeStamp'],
        'errorMsg': !exists(json, 'errorMsg') ? undefined : json['errorMsg'],
        'fileMetadata': !exists(json, 'fileMetadata') ? undefined : json['fileMetadata'],
        'predictionsFile': !exists(json, 'predictionsFile') ? undefined : json['predictionsFile'],
    };
}

export function SincerityResultToJSON(value?: SincerityResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': value.file,
        'srcID': value.srcID,
        'fileName': value.fileName,
        'subtitlesFile': value.subtitlesFile,
        'downloadFile': value.downloadFile,
        'status': SincerityStatusToJSON(value.status),
        'createdAt': (value.createdAt.toISOString()),
        'completedAt': value.completedAt === undefined ? undefined : (value.completedAt.toISOString()),
        'fileExtension': value.fileExtension,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'totalAssessments': value.totalAssessments,
        'numLikelyTrue': value.numLikelyTrue,
        'numLikelyUntrue': value.numLikelyUntrue,
        'numNeutral': value.numNeutral,
        'feedbackRating': value.feedbackRating,
        'feedbackComment': value.feedbackComment,
        'feedbackTimeStamp': value.feedbackTimeStamp,
        'errorMsg': value.errorMsg,
        'fileMetadata': value.fileMetadata,
        'predictionsFile': value.predictionsFile,
    };
}

